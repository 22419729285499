import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import '@vkontakte/vkui/dist/vkui.css';
import Snackbar from '@vkontakte/vkui/dist/components/Snackbar/Snackbar';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import Icon24ErrorCircle from '@vkontakte/icons/dist/24/error_circle';

import Home from './panels/Home';
import Ad from './panels/Ad';

const App = () => {
	const [activePanel, setActivePanel] = useState('home');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large'/>);
	const [amount, setAmount] = useState();
	const [showSpinner, setShowSpinner] = useState();
	const [snackbar, setSnackbar] = useState();
	const [shareAmount, setShareAmount] = useState();
	const [buttonSpinner, setButtonSpinner] = useState();

	useEffect(() => {
		bridge.send("VKWebAppInit");
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setUser(user);
			setPopout(null);
		}
		fetchData();
	}, []);

	const getCookie = (name) => {
		let cookieValue = null;
		if (document.cookie && document.cookie !== '') {
			const cookies = document.cookie.split(';');
			for (let i = 0; i < cookies.length; i++) {
				const cookie = cookies[i].trim();
				if (cookie.substring(0, name.length + 1) === (name + '=')) {
					cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
					break;
				}
			}
		}
		return cookieValue;
	}

	const go = (panel) => {
		setButtonSpinner(true)
		bridge.send("VKWebAppShowNativeAds", {ad_format:"reward"})
		.then((data) => {
			if (data.result) {
				setButtonSpinner(false)
				setActivePanel(panel);
				let csrftoken = getCookie('csrftoken')
				setShowSpinner(<ScreenSpinner size='large'/>)
				fetch('/api/how-many/', {
					method: 'POST',
					headers: {
						'Content-type': 'application/json',
						'X-CSRFToken': csrftoken,
					},
					body: JSON.stringify({params: window.location.search})
				})
				.then(response => response.json())
				.then((data) => {
					setShowSpinner(null)
					if (data.code === 0) {
						setAmount(data.amount);
					}
					else {
						setSnackbar(<Snackbar
							layout='vertical'
							onClose={() => setSnackbar(null)}
							before={<Avatar size={20} style={{backgroundColor: 'var(--dynamic-red)'}}>
							<Icon24ErrorCircle fill='red' width='20' height='20'/>
							</Avatar>}
							duration={1100}>
								Произошла ошибка
							</Snackbar>)
					}
				})
				.catch((error) => {
					setShowSpinner(null)
					setAmount(0)
					setSnackbar(<Snackbar
						layout='vertical'
						onClose={() => setSnackbar(null)}
						before={<Avatar size={20} style={{backgroundColor: 'var(--dynamic-red)'}}>
						<Icon24ErrorCircle fill='red' width='20' height='20'/>
						</Avatar>}
						duration={1100}>
							Произошла ошибка
						</Snackbar>)
				})
			}
			else {
				setSnackbar(<Snackbar
					layout='vertical'
					onClose={() => setSnackbar(null)}
					before={<Avatar size={20} style={{backgroundColor: 'var(--dynamic-red)'}}>
					<Icon24ErrorCircle fill='red' width='20' height='20'/>
					</Avatar>}
					duration={1100}>
						Не удалось загрузить рекламу
					</Snackbar>)
			}
		})
		.catch(error => {
			setButtonSpinner(false)
			setSnackbar(<Snackbar
				layout='vertical'
				onClose={() => setSnackbar(null)}
				before={<Avatar size={20} style={{backgroundColor: 'var(--dynamic-red)'}}>
				<Icon24ErrorCircle fill='red' width='20' height='20'/>
				</Avatar>}
				duration={1100}>
					Произошла ошибка
				</Snackbar>)
		})
		
	};

	const share = () => {
		bridge.send("VKWebAppShare", {})
		.then(() => {
			let csrftoken = getCookie('csrftoken')
			setShowSpinner(<ScreenSpinner size='large'/>)
			fetch('/api/how-many-shared/', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					'X-CSRFToken': csrftoken,
				},
				body: JSON.stringify({params: window.location.search})
			})
			.then(response => response.json())
			.then((data) => {
				setShowSpinner(null)
				if (data.code === 0) {
					setShareAmount(data.amount)
				}
				else {
					setSnackbar(<Snackbar
						layout='vertical'
						onClose={() => setSnackbar(null)}
						before={<Avatar size={20} style={{backgroundColor: 'var(--dynamic-red)'}}>
						<Icon24ErrorCircle fill='red' width='20' height='20'/>
						</Avatar>}
						duration={1100}>
							Произошла ошибка
						</Snackbar>)
				}
			})
			.catch((error) => {
				setShowSpinner(null)
				setShareAmount("0")
				setSnackbar(<Snackbar
					layout='vertical'
					onClose={() => setSnackbar(null)}
					before={<Avatar size={20} style={{backgroundColor: 'var(--dynamic-red)'}}>
					<Icon24ErrorCircle fill='red' width='20' height='20'/>
					</Avatar>}
					duration={1100}>
						Произошла ошибка
					</Snackbar>)
			})
		})
	}

	return (
		<View activePanel={activePanel} popout={popout}>
			<Home id='home' fetchedUser={fetchedUser} go={go} snackbar={snackbar} showSpinner={showSpinner} buttonSpinner={buttonSpinner} />
			<Ad id='ad' amount={amount} snackbar={snackbar} showSpinner={showSpinner} share={share} shared={shareAmount} />
		</View>
	);
}

export default App;

