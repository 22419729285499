import React from 'react';
import { Div, Button, Group } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Icon28StatisticCircleFillBlue from '@vkontakte/icons/dist/28/statistic_circle_fill_blue';
import './style.css';
import Icon28ShareOutline from '@vkontakte/icons/dist/28/share_outline';


const Ad = ({id, amount, shared, share, snackbar, showSpinner}) => (
	<Panel id={id} separator={false} centered={true}>
		<Div className="center">
			<Icon28StatisticCircleFillBlue width={200} height={200}/>
			<h2>Рекламу посмотрели: <br/>{amount}</h2>
			{!shared &&
				<Group>
					<h3>Вы можете поделиться приложением, чтобы узнать, сколько человек поделились приложением:</h3>
					<Button mode="secondary" size="xl" before={<Icon28ShareOutline/>} onClick={() => share()}>Поделиться</Button>
				</Group>
			}
			{shared &&
			<Group>
				<h2>Приложением поделились:<br/>{shared}</h2>
			</Group>
			}
		</Div>
		{snackbar}
		{showSpinner}
	</Panel>
);

export default Ad;
