import React, { Fragment } from 'react';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import './style.css';
import Icon28VideoCameraCircleFillRed from '@vkontakte/icons/dist/28/video_camera_circle_fill_red';
import { Spinner } from '@vkontakte/vkui';
import Icon16PlayCircleFillPink from '@vkontakte/icons/dist/16/play_circle_fill_pink';
const Home = ({ id, go, fetchedUser, snackbar, showSpinner, buttonSpinner }) => (
	<Panel id={id} separator={false} centered={true}>
		<Fragment>
			<Group>
				<Div className="center">
					<Icon16PlayCircleFillPink width={200} height={200} style={{paddingBottom: 20}}/>
					<h3>Посмотрите рекламу, чтобы узнать, сколько человек посмотрели рекламу, чтобы узнать, сколько человек посмотрели рекламу...</h3>
					{!buttonSpinner &&
					<Button mode="secondary" size="xl" onClick={() => go('ad')}>Посмотреть</Button>
					
					}
					{buttonSpinner &&
					<Button mode="secondary" size="xl" onClick={() => go('ad')} disabled before={<Spinner size="regular"/>}>Загрузка рекламы</Button>
					}
					
					
				</Div>
			</Group>
		</Fragment>
		{snackbar}
		{showSpinner}
	</Panel>
);

export default Home;
